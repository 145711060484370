@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800;900&display=swap');

html {
  box-sizing: border-box;
  font-size: 1.34vh;
}

/* @media (max-width: 650px) {
  html {
    font-size: 10px;
  }
}
@media (max-height: 650px) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 11px;
  }
}
@media (max-height: 760px) {
  html {
    font-size: 11px;
  }
} */

html, html * {
  font-family: 'Manrope', sans-serif;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body, h1, h2, h3, h4, h5, h6, p, ol, ul, div, figure, figcation {
  margin: 0;
  padding: 0;
}

/* ul {
  list-style: none;
} */

div {
  width: 100%;
  height: auto;
}

a, a:any-link {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* TODO: remove after theme-ui default is fixed */
/* p {
  font-family: "Open Sans", OpenSans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #000;
} */

/* smooth scrollbar */
body {
  width: auto;
  height: 100vh;
  overflow: hidden;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: black !important;
}

.gs-dev-tools {
  z-index: 99999;
}

.gsap-marker-start, .gsap-marker-end {
  right: 0 !important;
}